const defaultState = {
  modalOpened: false,
}


export default function app(state = defaultState, action) {
  switch (action.type) {
    case 'OPEN_MODAL': return {
      ...state,
      modalOpened: action.modal,
    }

    default: return state
  }
}
