import React from 'react'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'

import reducers from './reducers'


const logger = createLogger({})
const store = createStore(reducers, applyMiddleware(thunk, logger))


export default ({ element }) => (
  <Provider store={store}>{element}</Provider>
)
