/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/reset.css'
import './src/global.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()


export { default as wrapRootElement } from './src/ReduxWrapper'
